/* Share article: FB/Twitter */
let articleUrl = document.location.href;
let articleUrlDecoded = encodeURIComponent(articleUrl);
let articleHeadline = document.querySelector('.molecule-article__headline');

let copyUrlShare = document.querySelector('.molecule-sharearticle .copyUrl');
let facebookShare = document.querySelector('.molecule-sharearticle .fb-share-button');
let twitterShare = document.querySelector('.molecule-sharearticle .tw-share-button');
let linkedinShare = document.querySelector('.molecule-sharearticle .in-share-button');
let mailShare = document.querySelector('.molecule-sharearticle .mail-share-button');
let whatsAppShare = document.querySelector('.molecule-sharearticle .whatsapp');

if (copyUrlShare){
    copyUrlShare.addEventListener('click', function () {
        copyTextToClipboard(articleUrl);
    });
}

if (facebookShare) {
    facebookShare.href = 'https://www.facebook.com/sharer/sharer.php?u=' + articleUrlDecoded + '&scrape=true';
}

if (twitterShare) {
    twitterShare.href = 'https://twitter.com/intent/tweet?source=' + articleUrlDecoded + '&text=' + cleanSpecialChars(articleHeadline.innerHTML) + ': ' + articleUrlDecoded;
}
if (linkedinShare) {
    linkedinShare.href = 'https://www.linkedin.com/feed/?shareActive=true&shareUrl=' + articleUrlDecoded;
}

if (whatsAppShare) {
    whatsAppShare.href = 'whatsapp://send?text='+ cleanSpecialChars(articleHeadline.innerHTML) +'%20' + articleUrl;
}

/* Share article: Mail */
let start = 'Das könnte für dich interessant sein';

if (mailShare) {
    mailShare.href = 'mailto:?subject=Das%20k%C3%B6nnte%20f%C3%BCr%20dich%20interessant%20sein&body=Hallo%2C%0A%0Adas%20hier%20ist%20interessant%20%E2%80%93%20kommt%20von%20om-online.de%3A%0A%0A' + articleUrl;
}

function cleanSpecialChars(text) {
    return encodeURIComponent(text.replace(/&amp;/g, '&').replace(/&nbsp;/g,' '));
}

/* Copy article url to clipboard */
function copyTextToClipboard(text) {
    let textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
        let successful = document.execCommand('copy');
        let msg = successful ? 'successful' : 'unsuccessful';
        alert('Artikel-URL wurde erfolgreich in die Zwischenablage kopiert.');
    } catch (err) {
        console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
}

