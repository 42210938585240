(function () {
window.driveStart = driveStart;


function getDataLayerValue(value) {
    return window.dataLayer.find(obj => obj.hasOwnProperty(value))?.[value]
}

function sendData() {
    (function (p, l, o, w, i, n, g) {
        if (!p[i]) {
            p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
            p.GlobalSnowplowNamespace.push(i);
            p[i] = function () {
                (p[i].q = p[i].q || []).push(arguments);
            }
            p[i].q = p[i].q || [];
            n = l.createElement(o);
            g = l.getElementsByTagName(o)[0];
            n.async = 1;
            n.src = w;
            g.parentNode.insertBefore(n, g)
        }
    }(window, document, "script", "/assets/latest.js", "drive"));
    const user = tp?.pianoId?.getUser() || null;
    const sso_id = user?.['uid'];
    const articleType = getDataLayerValue('article_type') || '';
    const articleId = getDataLayerValue('documentId');
    const contentType = getDataLayerValue('contentType') || 'other';
    const contentIsPaywall = window.dataLayer.find(obj => obj.hasOwnProperty('article_type'))?.['article_type'] === 'plus_paywall';
    console.log(window.pianoResponseVariables?.['driveUserTypeIsPremium']);
    const userType = user ? (window.pianoResponseVariables?.['driveUserTypeIsPremium'] ? 'premium' : 'registered') : 'anonym';
    const appId = process.env.NODE_ENV === 'production' ? 'om-medien' : 'om-medien-test'

    let globalContexts = [
        {
            schema: 'iglu:de.drive/user_context/jsonschema/1-0-0',
            data: {userType: userType}
        },
        {
            schema: 'iglu:de.drive/article_context/jsonschema/1-0-0',
            data: {
                articleId: String(articleId),
                articleType: articleType === 'plus_paywall' ? 'plus' : articleType,
                contentType: contentType,
                contentIsPaywall: contentIsPaywall
            }
        }
    ];
    window.drive('newTracker', 'DriveTracker', 'drive-v2.om-online.de', {
        appId: appId,
        discoverRootDomain: true,
        contexts: {
            webPage: true
        }
    });
    window.drive('addGlobalContexts', globalContexts);
    window.drive('setUserId', '' + sso_id);
    window.drive('enableActivityTracking', 5, 5);
    window.drive('trackPageView');

    function funnelentry(e) {
        console.log(e)
        const link = e.target;
        if (link == null) { return; }
        const label = link.getAttribute("data-drive") || 'direct'
        const mapping = [
            { key: 'plus', value: 'om-plus' },
            { key: 'premium', value: 'om-premium' },
            { key: 'komplett', value: 'om-komplett' }
        ];

        const product = (mapping.find(({ key }) => link.href.includes(key)) || {}).value || '';
        const price = '';
        console.log(label)
        console.log(product)
        window.drive('trackSelfDescribingEvent', {
            schema: 'iglu:de.drive/subscription_context/jsonschema/1-0-0',
            data: { action: 'funnel-entry',
                label: label,
                product: product,
                price: price
            }
        });
    }

    document.querySelectorAll('a').forEach(link => {
        if (link.href.includes('mein.om-online.de/angebote/')) {
            console.log(link.href)
            link.addEventListener('click', funnelentry, false);
        }
    });
}

function driveStart () {
   if ( window.drive ) { return; }
    const checkStatus = setInterval(() => {
        if (window.pianoResponseVariables?.hasOwnProperty('driveUserTypeIsPremium')) {
            console.log("Status gefunden:", window.pianoResponseVariables?.['driveUserTypeIsPremium']);
            sendData();
           clearInterval(checkStatus);
        }
    }, 100);
}
}());