function waitForGlobal(globalVar, callback) {
    if (window[globalVar]) {
        callback();
    } else {
        const interval = setInterval(() => {
            if (window[globalVar]) {
                clearInterval(interval);
                callback();
            }
        }, 50); // Überprüfen alle 50ms
    }
}

(function () {
    const pianoFunction = function () {
        let loginButton = document.querySelectorAll('.login');
        let logoutButton = document.querySelectorAll('.logout');
        let accountListItem = document.querySelectorAll('.accountContainer');

        loginButton.forEach(item => {
            if (tp.pianoId.getUser()) {
                item.classList.add('hide');
                accountListItem.forEach(item => {
                    item.classList.add('show');
                });
                logoutButton.forEach(item => {
                    item.classList.add('show');
                    item.classList.remove('hide');
                });
            } else {
                accountListItem.forEach(item => {
                    item.classList.add('hide');
                    item.classList.remove('show');
                });
                logoutButton.forEach(item => {
                    item.classList.add('hide');
                    item.classList.remove('show');
                });
            }
        });
        logoutButton.forEach( button => {
            button.addEventListener('click', async function () {
                try {
                    await tp.pianoId.logout();
                    console.log('Logout erfolgreich');
                    window.location.reload();
                } catch (error) {
                    console.error('Fehler beim Logout:', error);
                }
            })
        })
    }

    const paywallCheck = function () {
        console.log('Paywall check');
        const hasPaywall = window.dataLayer.find(obj => obj.hasOwnProperty('article_type'))?.['article_type'] === 'plus_paywall';
        const hasPremium = window.pianoResponseVariables?.['driveUserTypeIsPremium'] ?? false;

        console.log(hasPaywall);
        console.log(hasPremium);

        if(hasPaywall && hasPremium) {
            console.log('location reload')
            location.reload(true);
        }
    }

    document.addEventListener('DOMContentLoaded', () => {
        const currentUrl = window.location.href;
        const links = document.querySelectorAll('.piano-login-url');
        links.forEach(link => {
            link.href += `?ref=${currentUrl}&reason=Portal`;
        });
        const abolinks = document.querySelectorAll('.piano-abo-url');
        abolinks.forEach(abolink => {
            abolink.href += `?origin=${currentUrl}`;
        });
    })
    document.addEventListener('readystatechange', () => {
        if (document.readyState === 'complete') {
            waitForGlobal('tp', () => {
                console.log('tp ist geladen!');
                pianoFunction();
                paywallCheck();
            });
        }
    });
})();
